import {
  DateInput,
  Column,
  Input,
  RadioGroup,
  RadioValue,
  randomString,
  Row,
  Select,
  ValueOpt,
  Typography,
  createCssVariableString,
} from "best-common-react";
import React, { useState } from "react";
import styled from "styled-components";
import { useDropdowns } from "../../contexts/DropdownsContext";

const DateHolder = styled.div`
  margin-left: 1rem;
  @media (min-width: 992px) and (max-width: 1200px) {
    margin-left: 0.25rem;
  }
`;

const BeforeHolder = styled.div`
  margin-top: 1rem;
  margin-left: 1rem;
  @media (min-width: 992px) and (max-width: 1200px) {
    margin-left: 0.25rem;
  }
`;

type AccessCutoffProps = {
  override: any;
  setOverride: (value: any) => void;
  isCutoff?: boolean;
  timezone?: string;
};

const AccessCutoff = ({ override, setOverride, isCutoff = false }: AccessCutoffProps) => {
  const [id] = useState<string>(randomString());
  const { active = true, date, hoursBefore, useDate } = override;
  const { hoursOptions } = useDropdowns();

  const handleDaysChange = (value: number) => {
    if (!isNaN(value)) {
      setOverride({ ...override, hoursBefore: (hoursBefore % 24) + value * 24 });
    }
  };

  const changeType = (value: RadioValue) => {
    setOverride({
      ...override,
      useDate: value === 1,
    });
  };

  return (
    <>
      <RadioGroup
        id={`cutoff-type-${id}`}
        name={`cutoff-type-${id}`}
        disabled={!active}
        value={useDate ? 1 : 2}
        options={[
          {
            value: 1,
            label: "Start Date",
          },
          {
            value: 2,
            label: "Offset From Game Time",
          },
        ]}
        onChange={(value) => {
          changeType(value);
        }}
        gutterBottom
      />
      {useDate ? (
        <DateHolder>
          <DateInput
            id={`overrideDate-${id}`}
            label={!isCutoff ? "Start Date" : "End Date"}
            value={date ? new Date(date) : null}
            showTimeSelect={true}
            disabled={!active || !useDate}
            onChange={(value: Date) => setOverride({ ...override, date: value })}
            withPortal
            gutterBottom
          />
        </DateHolder>
      ) : (
        <>
          <Row>
            <Column width={2}>
              <Input
                id={`days-change-${id}`}
                disabled={!active || useDate}
                type="number"
                onChange={(value: string) => handleDaysChange(parseInt(value))}
                value={Math.floor(hoursBefore / 24)}
                gutterBottom
              />
              <Typography
                variant="labelSmall"
                elementStyles={{
                  color: !active || useDate ? createCssVariableString("onColorsOnSurfaceLow") : undefined,
                }}
              >
                {Math.floor(hoursBefore / 24) === 1 ? "day" : "days"}
              </Typography>
            </Column>
            <Column width={2}>
              <Select
                id="hoursBefore"
                disabled={!active || useDate}
                options={hoursOptions}
                value={{
                  label: hoursBefore % 24,
                  value: hoursBefore % 24,
                }}
                onChange={(value: ValueOpt<number>) =>
                  setOverride({ ...override, hoursBefore: Math.floor(hoursBefore / 24) * 24 + value.value })
                }
                gutterBottom
              />
              <Typography
                variant="labelSmall"
                elementStyles={{
                  color: !active || useDate ? createCssVariableString("onColorsOnSurfaceLow") : undefined,
                }}
              >
                {hoursBefore % 24 === 1 ? "hr" : "hrs"}
              </Typography>
            </Column>
          </Row>
          <BeforeHolder>
            <Select
              id={`before-${id}`}
              disabled={!active || useDate}
              options={[
                {
                  label: "Before",
                  value: "Before",
                },
              ]}
              value={{
                label: "Before",
                value: "Before",
              }}
              onChange={() => {}}
              gutterBottom
            />
          </BeforeHolder>
        </>
      )}
    </>
  );
};

export default AccessCutoff;
