import {
  createRouteSearch,
  DataTable,
  DataTableColumn,
  DataTableDataType,
  getSortDir,
  includes,
  SortDirection,
  SortFilters,
  sortWithFunction,
  toLower,
  toMoney,
  useLoading,
} from "best-common-react";
import { Location } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getAllProjectRequestsTotals, runReport } from "../../../api/RequesTixApi";
import ReportConstants from "../../../constants/ReportConstants";
import { ProjectAllTotalsDTO } from "../../../types/Project";
import { RequestixLocationState } from "../../../types/Routing";
import { downloadLink, FileTypes } from "../../../util/FileUtil";
import ExportFormatter from "../formatters/ExportFormatter";
import ToLinkFormatter, { ToLinkValue } from "../formatters/ToLinkFormatter";
import { ReportHandlerTypeProps } from "../ReportHandler";
import ProjectRequestsAllProjectsAccordion from "./ProjectRequestsAllProjectsAccordion";

export type AllProjectRequestData = DataTableDataType & {
  projectId: number;
  projectName: string;
  userCount: number;
  projectIdObj: {
    value: number;
    onClick: () => void;
  };
  projectNameObj: ToLinkValue;
  approved: ToLinkValue;
  comped: ToLinkValue;
  card: ToLinkValue;
  dept: ToLinkValue;
  denied: ToLinkValue;
  total: ToLinkValue;
  cardTotalCost: string;
  deptTotalCost: string;
  totalCost: string;
};

const Columns: DataTableColumn<AllProjectRequestData>[] = [
  {
    name: "",
    key: "projectIdObj",
    width: 40,
    readonlyFormatter: ExportFormatter,
  },
  {
    name: "Project",
    key: "projectNameObj",
    minWidth: 250,
    readonlyFormatter: ToLinkFormatter,
    sortable: true,
  },
  {
    name: "Users",
    key: "userCount",
    cellClass: "justify-content-center",
    width: 150,
    sortable: true,
  },
  {
    name: "Approved",
    key: "approved",
    cellClass: "justify-content-center",
    readonlyFormatter: ToLinkFormatter,
    width: 150,
    sortable: true,
  },
  {
    name: "Comp",
    key: "comped",
    cellClass: "justify-content-center",
    readonlyFormatter: ToLinkFormatter,
    width: 150,
    sortable: true,
  },
  {
    name: "Business Card",
    key: "card",
    cellClass: "justify-content-center",
    readonlyFormatter: ToLinkFormatter,
    width: 150,
    sortable: true,
  },
  {
    name: "Dept",
    key: "dept",
    cellClass: "justify-content-center",
    readonlyFormatter: ToLinkFormatter,
    width: 150,
    sortable: true,
  },
  {
    name: "Denied",
    key: "denied",
    cellClass: "justify-content-center",
    readonlyFormatter: ToLinkFormatter,
    width: 150,
    sortable: true,
  },
  {
    name: "Total",
    key: "total",
    cellClass: "justify-content-center",
    readonlyFormatter: ToLinkFormatter,
    width: 150,
    sortable: true,
  },
  {
    name: "Card Total",
    key: "cardTotalCost",
    cellClass: "justify-content-center",
    width: 150,
    sortable: true,
  },
  {
    name: "Dept Total",
    key: "deptTotalCost",
    cellClass: "justify-content-center",
    width: 150,
    sortable: true,
  },
  {
    name: "Total Cost",
    key: "totalCost",
    cellClass: "justify-content-center",
    width: 150,
    sortable: true,
  },
];

const massageData = (
  data: ProjectAllTotalsDTO[],
  location: Location<RequestixLocationState>,
  startDate: string,
  endDate: string,
  generateReport: (projectId: number) => void
): AllProjectRequestData[] => {
  const to = location.pathname;
  return data.map((d: ProjectAllTotalsDTO) => ({
    projectId: d.projectId,
    projectName: d.projectName,
    userCount: d.userCount,
    projectIdObj: {
      value: d.projectId,
      onClick: () => generateReport(d.projectId),
    },
    projectNameObj: {
      value: d.projectName,
      to: to,
      search: createRouteSearch({
        projectId: d.projectId,
        startDate,
        endDate,
      }),
    },
    approved: {
      value: d.approved,
      to: to,
      search: createRouteSearch({
        statusId: 3,
        projectId: d.projectId,
        startDate,
        endDate,
      }),
    },
    comped: {
      value: d.comped,
      to: to,
      search: createRouteSearch({
        statusId: 3,
        fulfillmentTypeId: 1,
        projectId: d.projectId,
        startDate,
        endDate,
      }),
    },
    card: {
      value: d.card,
      to: to,
      search: createRouteSearch({
        statusId: 3,
        fulfillmentTypeId: 2,
        projectId: d.projectId,
        startDate,
        endDate,
      }),
    },
    dept: {
      value: d.dept,
      to: to,
      search: createRouteSearch({
        statusId: 3,
        fulfillmentTypeId: 3,
        projectId: d.projectId,
        startDate,
        endDate,
      }),
    },
    denied: {
      value: d.denied,
      to: to,
      search: createRouteSearch({
        statusId: 4,
        projectId: d.projectId,
        startDate,
        endDate,
      }),
    },
    total: {
      value: d.total,
      to: to,
      search: createRouteSearch({
        projectId: d.projectId,
        startDate,
        endDate,
      }),
    },
    cardTotalCost: toMoney(d.cardTotalCost),
    deptTotalCost: toMoney(d.deptTotalCost),
    totalCost: toMoney(d.totalCost),
  }));
};

const freeTextFilter = (data: AllProjectRequestData[], search: string): AllProjectRequestData[] =>
  search && search.length ? data.filter((data) => includes(data.projectNameObj.value?.toString(), search)) : data;

const ProjectRequestsAllProjects = ({ reportData, location, setCanExport }: ReportHandlerTypeProps) => {
  const { startDate, endDate } = reportData;
  const { setLoading } = useLoading();
  const [data, setData] = useState<AllProjectRequestData[]>([]);
  const [displayData, setDisplayData] = useState<AllProjectRequestData[]>([]);
  const [sortFilters, setSortFilters] = useState<SortFilters<AllProjectRequestData>>({
    key: "projectName",
    direction: "ASC",
  });

  const onSort = (key: keyof AllProjectRequestData, direction: SortDirection) => setSortFilters({ key, direction });

  const generateReport = async (projectId: number) => {
    try {
      setLoading(true);
      const response = await runReport(ReportConstants.PROJECT_REQUESTS_REPORT_ID, {
        startDate,
        endDate,
        projectId: projectId,
      });
      downloadLink(response, FileTypes.EXCEL);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (startDate?: string, endDate?: string) => {
    if (startDate && endDate) {
      try {
        setLoading(true);
        const data: ProjectAllTotalsDTO[] = await getAllProjectRequestsTotals(startDate, endDate);
        setData(massageData(data, location, startDate, endDate, generateReport));
        setCanExport(!!data.length);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    void fetchData(startDate, endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    if (data) {
      setDisplayData(
        sortWithFunction(
          freeTextFilter(data, reportData.search),
          sortFilters.key,
          getSortDir(sortFilters.direction),
          (value) => (value ? toLower(value.value) : "")
        )
      );
    } else {
      setDisplayData([]);
    }
  }, [data, sortFilters, reportData.search]);

  return (
    <DataTable
      className="mt-2 mt-md-0"
      columns={Columns}
      data={displayData}
      accordion={ProjectRequestsAllProjectsAccordion}
      sortColumn={sortFilters.key}
      sortDirection={sortFilters.direction}
      sortFunction={onSort}
      statusTextOverride={(_selected, total) => `${total} projects`}
    />
  );
};

export default ProjectRequestsAllProjects;
