import { Button, Checkbox, Column, Input, Modal, Row, StickyFooterButtons, Table } from "best-common-react";
import React from "react";
import styled from "styled-components";
import { defaultAccess, defaultCutoff } from "../../constants/CutoffConstants";
import { DepartmentBySeasonDTO } from "../../types/Department";
import AccessCutoffOverrider from "../cutoffs/AccessCutoffOverrider";
import PersonalOnOff from "../requests/personal/PersonalOnOff";

const TableData = styled.td`
  &&& {
    width: 50%;
    vertical-align: middle;
    > div {
      min-height: 3rem;
      display: flex;
      align-items: center;
      padding-left: 0.5rem;
    }
  }
`;

const AllotmentInput = styled.div`
  margin-right: 1rem;
  width: 3rem;
`;

const SelectedDepartments = styled.span`
  font-size: 0.8rem;
  color: ${(props) => props.theme.palette.onColorsOnSurfaceLow};
  font-style: italic;
`;

const Allotment = ({ type, value, onAmountChange, toggleUnlimited }) => (
  <TableData>
    <div>
      {!value.unlimited && (
        <AllotmentInput>
          <Input
            id={`${type}-amount`}
            key={`input-${type}`}
            value={value.amount}
            onChange={(event) => onAmountChange(type, event.target.value)}
          />
        </AllotmentInput>
      )}
      <Checkbox
        id={`checkbox-${type}`}
        key={`checkbox-${type}`}
        label="unlimited"
        checked={value.unlimited}
        onChange={() => toggleUnlimited(type)}
      />
    </div>
  </TableData>
);

const defaultAllot = {
  amount: null,
  unlimited: false,
};

type BulkEditDepartmentModalProps = {
  show: boolean;
  selected: DepartmentBySeasonDTO[];
  season: string;
  onClose: () => void;
  onSave: (value: any) => void;
};

const BulkEditDepartmentModal = ({ show, selected, season, onClose, onSave }: BulkEditDepartmentModalProps) => {
  const [values, setValues] = React.useState({
    personalAllotment: defaultAllot,
    businessAllotment: defaultAllot,
    access: defaultAccess,
    cutoff: defaultCutoff,
    personalRequests: null,
  });
  const [hasEdited, setHasEdited] = React.useState<boolean>(false);
  const [canSave, setCanSave] = React.useState<boolean>(false);

  React.useEffect(() => {
    const { access, cutoff } = values;
    const accessValid =
      !access.active || (access.useDate && access.date) || (!access.useDate && access.hoursBefore > 0);
    const cutoffPersonalValid =
      (cutoff.personalCutoffUseDate && cutoff.personalCutoffDate) ||
      (!cutoff.personalCutoffUseDate && cutoff.personalCutoffHoursBefore > 0);
    const cutoffBusinessValid =
      (cutoff.businessCutoffUseDate && cutoff.businessCutoffDate) ||
      (!cutoff.businessCutoffUseDate && cutoff.businessCutoffHoursBefore > 0);
    const cutoffValid = cutoff.active === false || (cutoffPersonalValid && cutoffBusinessValid);
    setCanSave(!!hasEdited && !!accessValid && !!cutoffValid);
  }, [values]);

  const handleChange = (field, value) => {
    setHasEdited(true);
    setValues({ ...values, [field]: value });
  };

  const handleAmountChange = (field, value) => {
    handleChange(field, { ...values[field], amount: value });
  };

  const handleUnlimitedChange = (field) => {
    handleChange(field, { ...values[field], unlimited: !values[field].unlimited });
  };

  return (
    <Modal show={show} size="xl">
      <Modal.Header close={onClose}>Update {season}</Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-end mb-1">
          <SelectedDepartments>
            {selected.length + " department" + (selected.length !== 1 ? "s" : "") + " selected"}
          </SelectedDepartments>
        </div>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Header>Personal</Table.Header>
              <Table.Header>Business</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              <Allotment
                type="personalAllotment"
                value={values.personalAllotment}
                onAmountChange={handleAmountChange}
                toggleUnlimited={handleUnlimitedChange}
              />
              <Allotment
                type="businessAllotment"
                value={values.businessAllotment}
                onAmountChange={handleAmountChange}
                toggleUnlimited={handleUnlimitedChange}
              />
            </Table.Row>
          </Table.Body>
        </Table>
        <div className="mt-3">
          <PersonalOnOff
            value={values.personalRequests}
            onChange={(value) => handleChange("personalRequests", value)}
          />
        </div>
        <Row className="mt-3">
          <Column width={2}>
            <AccessCutoffOverrider override={values.access} setOverride={(value) => handleChange("access", value)} />
          </Column>
          <Column width={2}>
            <AccessCutoffOverrider
              override={values.cutoff}
              setOverride={(value) => handleChange("cutoff", value)}
              isCutoff={true}
            />
          </Column>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <StickyFooterButtons>
          <Button variant="primary" className="me-2" disabled={!canSave} onClick={() => onSave(values)}>
            Save
          </Button>
          <Button variant="default" onClick={onClose}>
            Close
          </Button>
        </StickyFooterButtons>
      </Modal.Footer>
    </Modal>
  );
};

export default BulkEditDepartmentModal;
