import { Card, Column, Row, Search, Select, useLoading, ValueOpt } from "best-common-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequestOnBehalfOf, getUsersRequests } from "../../api/RequesTixApi";
import MyRequestsTable from "../../components/requests/personal/MyRequestsTable";
import RouteConstants from "../../constants/RouteConstants";
import { useAuth } from "../../contexts/AuthContext";
import { useDropdowns } from "../../contexts/DropdownsContext";
import { RequestCategory } from "../../types/RequestType";
import { User } from "../../types/User";
import { UserTicketRequestDTO } from "../../types/UserRequest";
import { updateRouteParams, useRouteParams } from "../../util/RouteUtil";
import { requestsFreeTextSearch } from "../../util/TicketRequestUtil";

const RequestsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isCoordinator, userInfo } = useAuth();
  const { setLoading } = useLoading();
  const { requestCategoryOptions, gameOptions, requestStateFilterOptions, getOptionByValue } = useDropdowns();
  const { games, status = "", category, gamePk } = useRouteParams(location.search);
  const [onBehalfOf, setOnBehalfOf] = useState<User | undefined>(undefined);
  const [onBehalfOfOptions, setOnBehalfOfOptions] = useState<ValueOpt<User>[]>([]);
  const [requests, setRequests] = useState<UserTicketRequestDTO[]>([]);
  const [displayRequests, setDisplayRequests] = useState<UserTicketRequestDTO[]>([]);
  const [search, setSearch] = useState<string>("");
  const [activeGamePk, setActiveGamePk] = useState<number | undefined>(undefined);

  const fetchUsersRequests = async (userId: number) => {
    try {
      if (userId) {
        setLoading(true);
        const data: UserTicketRequestDTO[] = await getUsersRequests(userId, userId, games, category, status);
        setRequests(data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchOnBehalfOf = async () => {
    try {
      setLoading(true);
      const data: User[] = await getRequestOnBehalfOf(userInfo.employeeUserId);
      const options = data.map((user: User) => ({
        label: `${user.lastName}, ${user.firstName}`,
        value: user,
      }));
      setOnBehalfOfOptions(options);
      const selfOption = options.find((opt: ValueOpt<User>) => opt.value.employeeUserId === userInfo.employeeUserId);
      if (selfOption) {
        setOnBehalfOf(selfOption.value);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isCoordinator && userInfo?.employeeUserId) {
      void fetchOnBehalfOf();
    }
  }, [isCoordinator]);

  useEffect(() => {
    const userId = isCoordinator && onBehalfOf ? onBehalfOf.employeeUserId : userInfo ? userInfo.employeeUserId : null;
    void fetchUsersRequests(userId);
  }, [userInfo, onBehalfOf, games, status, category]);

  useEffect(() => {
    if (gamePk && requests.length) {
      setActiveGamePk(gamePk);
      setTimeout(() => {
        setActiveGamePk(null);
        updateRouteParams(navigate, RouteConstants.REQUESTS.BASE, {
          games,
          status,
          category,
        });
      }, 3000);
    }
  }, [gamePk, requests]);

  useEffect(() => {
    setDisplayRequests(requestsFreeTextSearch(requests, search));
  }, [requests, search]);

  return (
    <Card>
      <Card.Header>My Requests</Card.Header>
      <Card.Body>
        <Row>
          {isCoordinator && (
            <Column width={4}>
              <Select
                id="onBehalfOf"
                label="Requested on Behalf of"
                options={onBehalfOfOptions}
                value={getOptionByValue(onBehalfOfOptions, onBehalfOf)}
                onChange={(value: ValueOpt<User>) => setOnBehalfOf(value.value)}
                gutterBottom
              />
            </Column>
          )}
          <Column width={4}>
            <Select
              id="games"
              label="Games"
              options={gameOptions}
              value={gameOptions.find((option) => option.value == games)}
              onChange={({ value }: ValueOpt<number>) =>
                updateRouteParams(navigate, RouteConstants.REQUESTS.BASE, {
                  games: value,
                  status,
                  category,
                })
              }
              gutterBottom
            />
          </Column>
          <Column width={4}>
            <Select
              id="status"
              label="Status"
              options={requestStateFilterOptions}
              value={requestStateFilterOptions.find((option) => option.value.join() === status)}
              onChange={({ value }: ValueOpt<number[]>) =>
                updateRouteParams(navigate, RouteConstants.REQUESTS.BASE, {
                  games,
                  status: value.join(),
                  category,
                })
              }
              gutterBottom
            />
          </Column>
          <Column width={4}>
            <Select
              id="category"
              label="Category"
              options={requestCategoryOptions}
              value={requestCategoryOptions.find(({ value }) => value.requestCategoryId == category)}
              onChange={({ value }: ValueOpt<RequestCategory>) =>
                updateRouteParams(navigate, RouteConstants.REQUESTS.BASE, {
                  games,
                  status,
                  category: value.requestCategoryId,
                })
              }
              gutterBottom
            />
          </Column>
          <Column width={4}>
            <Search
              id="my-requests-search"
              value={search}
              onChange={(value: string) => setSearch(value)}
              placeholder="begin typing to filter requests"
              gutterBottom
            />
          </Column>
        </Row>
        <div className="mt-2">
          <MyRequestsTable
            requests={displayRequests}
            location={location}
            size={requests.length}
            activeGamePk={activeGamePk}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default RequestsPage;
