import {
  Accordion,
  Attribute,
  ConfirmationModal,
  CustomMobileAccordionType,
  DataTable,
  DataTableColumn,
  DateTimeFormatter,
  FormatterType,
  hexToRgb,
  IconFormatter,
  PriceFormatter,
  Select,
  TextFormatter,
  urlReplace,
  useLoading,
  useTheme,
  ValueOpt,
} from "best-common-react";
import React, { useCallback, useMemo, useState } from "react";
import { deleteSuiteRequest } from "../../../api/RequesTixApi";
import RouteConstants from "../../../constants/RouteConstants";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { GamePreviewDTO } from "../../../types/Game";
import { SuiteRequestDTO, SuiteRequestStatus, SuiteRequestStatusID, SuiteStatuses } from "../../../types/SuiteRequest";
import { UserDTO } from "../../../types/User";
import GamePreviewDisplay from "../../game/GamePreviewDisplay";
import BusinessFormatter from "../../tables/BusinessFormatter";
import { useNavigate } from "react-router-dom";

const SuiteUserFormatter = ({ row, ...rest }: FormatterType<SuiteRequestDTO>) => {
  const { onBehalfOfUser, requesterUser } = row;
  const user: UserDTO = onBehalfOfUser ?? requesterUser;
  return <TextFormatter {...rest} value={`${user?.lastName}, ${user?.firstName}`} />;
};

const SuiteDepartmentFormatter = ({ row, ...rest }: FormatterType<SuiteRequestDTO>) => {
  const { onBehalfOfUser, requesterUser } = row;
  const user: UserDTO = onBehalfOfUser ?? requesterUser;
  const department: string = user?.department;
  return <TextFormatter {...rest} value={department} />;
};

type AdminSuiteRequestGameProps = {
  game: GamePreviewDTO;
  requests: SuiteRequestDTO[];
  refreshData: () => void;
};

const AdminSuiteRequestGame = ({ game, requests, refreshData }: AdminSuiteRequestGameProps) => {
  const { Theme } = useTheme();
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { suiteRequestStatusOptions } = useDropdowns();
  const [requestToDelete, setRequestToDelete] = useState<SuiteRequestDTO | undefined>(undefined);

  const editRequest = (index: number) => {
    const value: SuiteRequestDTO = requests[index];
    goToRequest(value.suiteRequestId);
  };

  const openDeleteRequest = (_index: number, value: SuiteRequestDTO) => {
    setRequestToDelete(value);
  };

  const goToRequest = (suiteRequestId: number, suiteRequestStatusId?: number) => {
    const baseUrl: string = urlReplace(RouteConstants.ADMIN.SUITE_REQUESTS.EDIT, { suiteRequestId });
    const statusQuery: string = !!suiteRequestStatusId ? `?suiteRequestStatusId=${suiteRequestStatusId}` : "";
    navigate(baseUrl + statusQuery);
  };

  const deleteSuiteRequestCall = useCallback(async () => {
    try {
      if (requestToDelete) {
        setLoading(true);
        await deleteSuiteRequest(requestToDelete.suiteRequestId!);
        refreshData();
      }
      setRequestToDelete(undefined);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }, [requestToDelete]);

  const SuiteRequestFormatter = ({ value, row, rowIndex }: FormatterType<SuiteRequestDTO>) => {
    const suiteStatus: SuiteRequestStatus = value as SuiteRequestStatus;
    const optValue: ValueOpt<SuiteRequestStatus> | undefined = suiteRequestStatusOptions.find(
      (opt: ValueOpt<SuiteRequestStatus>) => opt.value.suiteStatusId === suiteStatus.suiteStatusId
    );

    const changeStatusValue = (value: ValueOpt<SuiteRequestStatus>) => {
      goToRequest(row.suiteRequestId, value?.value.suiteStatusId);
    };

    return (
      <Select
        className="pe-3"
        id={`suite-status-${rowIndex}`}
        options={suiteRequestStatusOptions}
        value={optValue}
        onChange={changeStatusValue}
      />
    );
  };

  const AdminSuiteRequestAccordion: React.FC<CustomMobileAccordionType<SuiteRequestDTO>> = ({ data, ...rest }) => {
    const { suiteRequestId, requesterUser, business, suiteRequestStatus, capacity, ticketBudget, submitted } = data;
    return (
      <Accordion {...rest}>
        <Accordion.Header>
          <div className="d-flex">
            <IconFormatter icon="fa-pencil" className="me-2" value={suiteRequestId} onClick={editRequest} />
            <SuiteUserFormatter value={requesterUser} row={data} />
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <Attribute header="Status" value={<SuiteRequestFormatter value={suiteRequestStatus} row={data} />} />
          <Attribute header="Department" value={<SuiteDepartmentFormatter value={requesterUser} row={data} />} />
          <Attribute header="Capacity" value={capacity} />
          <Attribute header="Budget" value={<PriceFormatter value={ticketBudget} row={data} />} />
          <Attribute header="Business" value={<BusinessFormatter value={business} row={data} />} />
          <Attribute header="Submitted" value={<DateTimeFormatter value={submitted} row={data} />} />
        </Accordion.Body>
      </Accordion>
    );
  };

  const Columns: DataTableColumn<SuiteRequestDTO>[] = useMemo(
    () => [
      {
        key: "suiteRequestId",
        name: "",
        readonlyFormatter: IconFormatter,
        icon: "fa-trash-alt",
        onClick: openDeleteRequest,
        width: 35,
      },
      {
        key: "suiteRequestId",
        name: "",
        readonlyFormatter: IconFormatter,
        icon: "fa-pencil-alt",
        onClick: editRequest,
        width: 35,
      },
      {
        key: "suiteRequestStatus",
        name: "Status",
        width: 200,
        editFormatter: SuiteRequestFormatter,
      },
      {
        key: "requesterUser",
        name: "Requester",
        minWidth: 300,
        readonlyFormatter: SuiteUserFormatter,
      },
      {
        key: "requesterUser",
        name: "Department",
        minWidth: 300,
        readonlyFormatter: SuiteDepartmentFormatter,
      },
      {
        key: "capacity",
        name: "Capacity",
        width: 100,
      },
      {
        key: "ticketBudget",
        name: "Budget",
        width: 100,
        readonlyFormatter: PriceFormatter,
      },
      {
        key: "business",
        name: "Business",
        minWidth: 300,
        readonlyFormatter: BusinessFormatter,
      },
      {
        key: "submitted",
        name: "Submitted",
        width: 200,
        readonlyFormatter: DateTimeFormatter,
      },
    ],
    [goToRequest, requests]
  );

  const rowStylesGetter = (_index: number, value: SuiteRequestDTO): object => {
    const statusId: SuiteRequestStatusID | undefined = value?.suiteRequestStatus?.suiteStatusId;
    switch (statusId) {
      case SuiteStatuses.APPROVED:
        return {
          background: hexToRgb(Theme.palette.coreColorsColoredSurfaceGreen, 0.4),
        };
      case SuiteStatuses.DENIED:
        return {
          background: hexToRgb(Theme.palette.coreColorsColoredSurfaceRed, 0.4),
        };
      case SuiteStatuses.IN_REVIEW:
        return {
          background: hexToRgb(Theme.palette.coreColorsColoredSurfaceBlue, 0.4),
        };
      case SuiteStatuses.OPEN:
      default:
        return {};
    }
  };

  return (
    <div>
      <ConfirmationModal
        show={!!requestToDelete}
        header="Delete Suite Request"
        body="Are you sure you want to delete this suite request?"
        onCancel={() => setRequestToDelete(undefined)}
        onConfirm={() => deleteSuiteRequestCall()}
      />
      <GamePreviewDisplay game={game} />
      <DataTable
        columns={Columns}
        data={requests}
        className="my-2"
        statusTextOverride={(_selected, total) => `${total} requests`}
        rowStylesGetter={rowStylesGetter}
        tableHeights={{
          noScroll: true,
        }}
        virtualized={false}
        accordion={AdminSuiteRequestAccordion}
      />
    </div>
  );
};

export default AdminSuiteRequestGame;
