import { Attribute, ExpansionType, GameInfo, Icon, Loader, Typography } from "best-common-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getRequestActions, getRequestSurroundingGames } from "../../../api/RequesTixApi";
import types from "../../../constants/FulfillmentTypeConstants";
import { APPROVED, PAY_BY_COMP } from "../../../constants/RequestTableConstants";
import { ActionDTO } from "../../../types/Action";
import { GamePreviewDTO } from "../../../types/Game";
import { TicketRequestDTO } from "../../../types/TicketRequest";
import { capitalizeFirstLetter } from "../../../util/StringUtil";
import AdminRequestActionCheckList from "./AdminRequestActionCheckList";
import RequestAllotmentTable from "./RequestAllotmentTable";

const SurroundingGames = styled.div`
  margin-left: 1rem;
`;

const AdminRequestExpansion = ({ value }: ExpansionType<TicketRequestDTO>) => {
  const {
    requestId,
    status,
    category,
    surroundingGames,
    comments,
    maxPrice,
    payBy,
    requestFulfillmentType,
    adminComments,
  } = value;
  const [games, setGames] = useState<GamePreviewDTO[]>([]);
  const [gamesLoading, setGamesLoading] = useState<boolean>(false);
  const [actions, setActions] = useState<ActionDTO[]>([]);
  const [actionsLoading, setActionsLoading] = useState<boolean>(false);

  const fetchActions = async () => {
    try {
      setActionsLoading(true);
      const res: ActionDTO[] = await getRequestActions(requestId);
      setActions(res);
    } catch (e) {
      console.error(e);
    } finally {
      setActionsLoading(false);
    }
  };

  const fetchSurroundingGames = async () => {
    try {
      setGamesLoading(true);
      const res: GamePreviewDTO[] = await getRequestSurroundingGames(requestId);
      setGames(res);
    } catch (e) {
      console.error(e);
    } finally {
      setGamesLoading(false);
    }
  };

  useEffect(() => {
    if (surroundingGames.length > 0) {
      void fetchSurroundingGames();
    }
  }, [surroundingGames]);

  useEffect(() => {
    void fetchActions();
  }, [requestId]);

  return (
    <div className="d-flex p-3">
      <div className="me-3">
        <RequestAllotmentTable requestId={requestId} category={category} />
        {(maxPrice != null && payBy !== PAY_BY_COMP) || status.requestStateId === APPROVED ? (
          <div className="mt-3">
            {maxPrice != null && payBy !== PAY_BY_COMP && <Attribute header="Max Ticket Price" value={maxPrice} />}
            {status.requestStateId === APPROVED && requestFulfillmentType.requestFulfillmentTypeId !== types.NONE && (
              <Attribute
                header="Fulfillment Type"
                value={capitalizeFirstLetter(requestFulfillmentType.requestFulfillmentTypeName)}
              />
            )}
          </div>
        ) : null}
      </div>
      <div className="me-3">
        <AdminRequestActionCheckList loading={actionsLoading} actions={actions} />
      </div>
      <div className="me-3">
        {games.length > 0 && (
          <div className="d-flex">
            <Icon iconName="fa-calendar" />
            {gamesLoading ? (
              <Loader type="spinner" size="40px" />
            ) : (
              <SurroundingGames>
                {games.map((game: GamePreviewDTO) => (
                  <div className="mb-2" key={game.gamePk}>
                    <GameInfo
                      gameDate={game.gameDate}
                      venue={game.venue}
                      seriesDescription={game.seriesDescription}
                      // tbd={!!game.status ? game.status.startTimeTBD : null}
                      home={game.teams.home.team}
                      away={game.teams.away.team}
                    />
                  </div>
                ))}
              </SurroundingGames>
            )}
          </div>
        )}
      </div>
      <div className="me-3">
        {comments && (
          <div className="d-flex">
            <Icon iconName="fa-comment" className="me-3" />
            <Typography>{comments}</Typography>
          </div>
        )}
      </div>
      <div className="me-3">
        {adminComments && (
          <div className="d-flex">
            <Icon iconName="fa-comment" className="me-3" />
            <Typography variant="headlineSmall">Admin Comments:</Typography>
            <Typography>{adminComments}</Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminRequestExpansion;
