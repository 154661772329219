import {
  DataTable,
  DataTableColumn,
  DataTableDataType,
  elementsAtIndex,
  IconLinkFormatter,
  SortDirection,
  SortFilters,
  tableSort,
} from "best-common-react";
import React, { useCallback, useEffect, useState } from "react";
import RouteConstants, { urlReplace } from "../../constants/RouteConstants";
import TableHeaders from "../../constants/UserTableHeaders";
import { UserDTO } from "../../types/User";
import UserAccordion from "./UserAccordion";

export type UserData = DataTableDataType & {
  editLink: string;
  id: number;
  user: UserDTO;
  name: string;
  department: string;
  personal_allotted: number;
  personal_pending: number;
  personal_approved: number;
  business_allotted: number;
  business_pending: number;
  business_approved: number;
  title: string;
  email: string;
  role: string;
  isSelected: boolean;
};

const cols: DataTableColumn<UserData>[] = [
  {
    key: "editLink",
    name: "",
    width: 40,
    cellClass: "justify-content-center justify-content-center",
    readonlyFormatter: IconLinkFormatter,
    icon: "fa-pencil-alt",
    fixed: true,
  },
  { key: "name", name: TableHeaders.name, minWidth: 250, sortable: true, fixed: true },
  { key: "department", name: TableHeaders.department, width: 200, sortable: true },
  { key: "title", name: TableHeaders.title, minWidth: 300, sortable: true },
  {
    key: "personal_pending",
    name: TableHeaders.pending,
    width: 100,
    cellClass: "justify-content-center",
    sortable: true,
  },
  {
    key: "personal_approved",
    name: TableHeaders.personal_approved,
    width: 100,
    cellClass: "justify-content-center",
  },
  { key: "personal_allotted", name: TableHeaders.allotted, width: 100, cellClass: "justify-content-center" },
  {
    key: "business_pending",
    name: TableHeaders.pending,
    width: 100,
    cellClass: "justify-content-center",
    sortable: true,
  },
  {
    key: "business_approved",
    name: TableHeaders.business_approved,
    width: 100,
    cellClass: "justify-content-center",
    sortable: true,
  },
  {
    key: "business_allotted",
    name: TableHeaders.allotted,
    width: 100,
    cellClass: "justify-content-center",
    sortable: true,
  },
  { key: "email", name: TableHeaders.email, minWidth: 200, sortable: true },
  { key: "role", name: TableHeaders.role, width: 100, sortable: true },
];

type UserTableProps = {
  values: UserDTO[];
  selected: number[];
  setSelected: (value: number[]) => void;
  searchTerm: string;
  loading?: boolean;
};

function UserTable({ values = [], selected, setSelected, searchTerm, loading = false }: UserTableProps) {
  const [users, setUsers] = useState<UserData[]>([]);
  const [sortFilters, setSortFilters] = useState<SortFilters<UserData>>({ key: "name", direction: "ASC" });

  const onSelectionChange = (rowIndexes: number[], isSelected: boolean) => {
    const users: UserDTO[] = elementsAtIndex(values, rowIndexes);
    const userIds: number[] = users.map((u: UserDTO) => u.employeeUserId);
    if (isSelected) {
      setSelected([...selected, ...userIds]);
    } else {
      setSelected(selected.filter((userId: number) => !userIds.includes(userId)));
    }
  };

  const isSelected = useCallback(
    (rowIndex: number): boolean => {
      const user: UserDTO = values[rowIndex];
      if (!!user) {
        return !!selected.includes(user?.employeeUserId);
      } else {
        return false;
      }
    },
    [values, selected]
  );

  useEffect(() => {
    const users: UserData[] = values
      .map((user: UserDTO) => {
        const personalAllotment = user.allotments.find((allotment) => allotment.allotmentType.allotmentTypeId === 1);
        const personal_allotted = personalAllotment
          ? personalAllotment.unlimited
            ? "unlimited"
            : personalAllotment.value
          : "N/A";
        const businessAllotment = user.allotments.find((allotment) => allotment.allotmentType.allotmentTypeId === 2);
        const business_allotted = businessAllotment
          ? businessAllotment.unlimited
            ? "unlimited"
            : businessAllotment.value
          : "N/A";
        return {
          editLink: urlReplace(RouteConstants.ADMIN.USERS_EDIT, { userId: user.employeeUserId }),
          id: user.employeeUserId,
          user: user,
          name: `${user.lastName}, ${user.firstName}`,
          department: user.department,
          personal_allotted,
          //@ts-ignore
          personal_pending: user.personal.pending,
          //@ts-ignore
          personal_approved: user.personal.approved,
          business_allotted,
          //@ts-ignore
          business_pending: user.business.pending,
          //@ts-ignore
          business_approved: user.business.approved,
          title: user.title,
          email: user.email ? user.email : "",
          role: user.role,
        } as UserData;
      })
      .filter((user) => {
        const compare = { ...user, editLink: "" };
        return Object.values(compare).some((value) => {
          if (value) {
            return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
          } else {
            return false;
          }
        });
      });
    setUsers(tableSort(users, sortFilters));
  }, [values, searchTerm]);

  useEffect(() => {
    setUsers(tableSort(users, sortFilters));
  }, [sortFilters]);

  return (
    <DataTable
      data={users}
      columns={cols}
      sortFunction={(key: keyof UserData, direction: SortDirection) => setSortFilters({ key, direction })}
      accordion={UserAccordion}
      sortColumn={sortFilters["key"]}
      sortDirection={sortFilters["direction"]}
      rowSelection={{
        isSelected,
        onSelectionChange,
      }}
      tableHeights={{
        headerHeight: 55,
      }}
      loading={loading}
      statusTextOverride={(selected, total) => (!!selected ? `${selected} of ${total} Users` : `${total} Users`)}
    />
  );
}

export default UserTable;
