import {
  ASC,
  createRouteSearch,
  DataTable,
  DataTableColumn,
  DataTableDataType,
  getSortDir,
  includes,
  SortDirection,
  SortFilters,
  sortWithFunction,
  toLower,
  useLoading,
} from "best-common-react";
import { Location } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getAllUsersRequests, runReport } from "../../../api/RequesTixApi";
import FulfillmentTypeConstants from "../../../constants/FulfillmentTypeConstants";
import GameQueryConstants from "../../../constants/GameQueryConstants";
import ReportConstants from "../../../constants/ReportConstants";
import { APPROVED, DENIED } from "../../../constants/RequestTableConstants";
import UserAllotmentConstants from "../../../constants/UserAllotmentConstants";
import { RequestixLocationState } from "../../../types/Routing";
import { UserRequestAggregationDTO } from "../../../types/UserRequest";
import { downloadLink, FileTypes } from "../../../util/FileUtil";
import ExportFormatter from "../formatters/ExportFormatter";
import ToLinkFormatter, { ToLinkValue } from "../formatters/ToLinkFormatter";
import AllUsersAccordion from "./AllUsersAccordion";

const Columns: DataTableColumn<AllUserRequestData>[] = [
  {
    name: "",
    key: "requesterId",
    width: 30,
    readonlyFormatter: ExportFormatter,
    sortable: true,
  },
  {
    name: "User",
    key: "recipient",
    minWidth: 200,
    readonlyFormatter: ToLinkFormatter,
    sortable: true,
  },
  {
    name: "Department",
    key: "department",
    minWidth: 240,
    readonlyFormatter: ToLinkFormatter,
    sortable: true,
  },
  {
    name: "Approved",
    key: "personalApproved",
    width: 90,
    cellClass: "text-center",
    readonlyFormatter: ToLinkFormatter,
    sortable: true,
  },
  {
    name: "Comp",
    key: "personalComp",
    width: 75,
    cellClass: "text-center",
    readonlyFormatter: ToLinkFormatter,
    sortable: true,
  },
  {
    name: "Personal Card",
    key: "personalCard",
    width: 70,
    cellClass: "text-center",
    readonlyFormatter: ToLinkFormatter,
    sortable: true,
  },
  {
    name: "Denied",
    key: "personalDenied",
    width: 75,
    cellClass: "text-center",
    readonlyFormatter: ToLinkFormatter,
    sortable: true,
  },
  {
    name: "Total",
    key: "personalTotal",
    width: 70,
    cellClass: "text-center",
    readonlyFormatter: ToLinkFormatter,
    sortable: true,
  },
  {
    name: "Approved",
    key: "businessApproved",
    width: 75,
    cellClass: "text-center",
    readonlyFormatter: ToLinkFormatter,

    sortable: true,
  },
  {
    name: "Comp",
    key: "businessComp",
    width: 75,
    cellClass: "text-center",
    readonlyFormatter: ToLinkFormatter,

    sortable: true,
  },
  {
    name: "Business Card",
    key: "businessCard",
    width: 70,
    cellClass: "text-center",
    readonlyFormatter: ToLinkFormatter,

    sortable: true,
  },
  {
    name: "Dept",
    key: "businessDept",
    width: 75,
    cellClass: "text-center",
    readonlyFormatter: ToLinkFormatter,

    sortable: true,
  },
  {
    name: "Denied",
    key: "businessDenied",
    width: 75,
    cellClass: "text-center",
    readonlyFormatter: ToLinkFormatter,

    sortable: true,
  },
  {
    name: "Total",
    key: "businessTotal",
    width: 70,
    cellClass: "text-center",
    readonlyFormatter: ToLinkFormatter,

    sortable: true,
  },
];

export type AllUserRequestData = DataTableDataType & {
  requesterId: {
    value: number;
    onClick: (userId: number) => void;
  };
  recipient: ToLinkValue;
  department: ToLinkValue;
  personalComp: ToLinkValue;
  personalApproved: ToLinkValue;
  personalCard: ToLinkValue;
  personalDenied: ToLinkValue;
  personalTotal: ToLinkValue;
  businessComp: ToLinkValue;
  businessApproved: ToLinkValue;
  businessCard: ToLinkValue;
  businessDept: ToLinkValue;
  businessDenied: ToLinkValue;
  businessTotal: ToLinkValue;
};

const massageData = (
  data: UserRequestAggregationDTO[],
  seasonId: number,
  departmentId: number,
  location: Location<RequestixLocationState>,
  generateReport: (userId: number) => void
): AllUserRequestData[] => {
  const to: string = location.pathname;
  return data.map((d: UserRequestAggregationDTO) => ({
    requesterId: {
      value: d.requesterId,
      onClick: generateReport,
    },
    recipient: {
      value: d.requester,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
      }),
    },
    department: {
      value: d.department,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId: d.departmentId,
      }),
    },
    personalComp: {
      value: d.personal.comp,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
        categoryId: UserAllotmentConstants.PERSONAL_ID,
        fulfillmentTypeId: FulfillmentTypeConstants.COMP,
      }),
    },
    personalApproved: {
      value: d.personal.approved,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
        categoryId: UserAllotmentConstants.PERSONAL_ID,
        statusId: APPROVED,
      }),
    },
    personalCard: {
      value: d.personal.card,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
        categoryId: UserAllotmentConstants.PERSONAL_ID,
        fulfillmentTypeId: FulfillmentTypeConstants.CHARGE_CARD,
      }),
    },
    personalDenied: {
      value: d.personal.denied,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
        categoryId: UserAllotmentConstants.PERSONAL_ID,
        statusId: DENIED,
      }),
    },
    personalTotal: {
      value: d.personal.total,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
        categoryId: UserAllotmentConstants.PERSONAL_ID,
      }),
    },
    businessComp: {
      value: d.business.comp,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
        categoryId: UserAllotmentConstants.BUSINESS_ID,
        fulfillmentTypeId: FulfillmentTypeConstants.COMP,
      }),
    },
    businessApproved: {
      value: d.business.approved,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
        categoryId: UserAllotmentConstants.BUSINESS_ID,
        statusId: APPROVED,
      }),
    },
    businessCard: {
      value: d.business.card,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
        categoryId: UserAllotmentConstants.BUSINESS_ID,
        fulfillmentTypeId: FulfillmentTypeConstants.CHARGE_CARD,
      }),
    },
    businessDept: {
      value: d.business.dept,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
        categoryId: UserAllotmentConstants.BUSINESS_ID,
        fulfillmentTypeId: FulfillmentTypeConstants.CHARGE_DEPT,
      }),
    },
    businessDenied: {
      value: d.business.denied,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
        categoryId: UserAllotmentConstants.BUSINESS_ID,
        statusId: DENIED,
      }),
    },
    businessTotal: {
      value: d.business.total,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
        categoryId: UserAllotmentConstants.BUSINESS_ID,
      }),
    },
  }));
};

const secondarySort = (a, b) => (a.submitted > b.submitted ? 1 : a.submitted < b.submitted ? -1 : 0);

const freeTextFilter = (requests: AllUserRequestData[], search: string | undefined) =>
  search && search.length
    ? requests.filter(
        (request) =>
          includes(request.recipient.value?.toString(), search) ||
          includes(request.department.value?.toString(), search)
      )
    : requests;

const AllUsersRequests = ({ seasonId, departmentId, search, location, setCanExport }) => {
  const { setLoading } = useLoading();
  const [requests, setRequests] = useState<AllUserRequestData[]>([]);
  const [displayData, setDisplayData] = useState<AllUserRequestData[]>([]);
  const [sortFilters, setSortFilters] = useState<SortFilters<AllUserRequestData>>({ key: "recipient", direction: ASC });

  const onSort = (key: keyof AllUserRequestData, direction: SortDirection) => setSortFilters({ key, direction });

  const generateReport = (userId: number) => {
    setLoading(true);
    runReport(ReportConstants.USER_REQUESTS_REPORT_ID, { seasonId, departmentId, userId }).then((response) => {
      downloadLink(response, FileTypes.EXCEL);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getAllUsersRequests(GameQueryConstants.PAST_ID, null, "", seasonId, null, departmentId).then((data) => {
      setRequests(massageData(data, seasonId, departmentId, location, generateReport));
      setLoading(false);
      setCanExport(!!data.length);
    });
  }, [seasonId, departmentId]);

  useEffect(() => {
    if (requests) {
      setDisplayData(
        sortWithFunction(
          freeTextFilter(requests, search),
          sortFilters.key,
          getSortDir(sortFilters.direction),
          (value) => (value ? toLower(value.value) : ""),
          secondarySort
        )
      );
    } else {
      setDisplayData([]);
    }
  }, [requests, search, sortFilters]);

  const displayText =
    requests.length === displayData.length
      ? `${displayData.length} users`
      : `${displayData.length} of ${requests.length} users`;

  return (
    <DataTable
      columns={Columns}
      data={displayData}
      accordion={AllUsersAccordion}
      tableHeights={{
        headerHeight: 50,
      }}
      sortColumn={sortFilters.key}
      sortDirection={sortFilters.direction}
      sortFunction={onSort}
      statusTextOverride={() => displayText}
    />
  );
};

export default AllUsersRequests;
