import { Card, Title, Typography, urlReplace, useBreakpoints, useLoading } from "best-common-react";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getActivePurchaseOpportunities } from "../../api/RequesTixApi";
import MyPurchaseOppTable from "../../components/purchaseOpportunity/MyPurchaseOppTable";
import PurchaseOpportunityCard from "../../components/purchaseOpportunity/PurchaseOpportunityCard";
import RouteConstants from "../../constants/RouteConstants";
import { PurchaseOpportunityDTO } from "../../types/PurchaseOpportunity";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const PurchaseOpportunityList: React.FC = () => {
  const { isMobile } = useBreakpoints();
  const navigate = useNavigate();
  const { loading, setLoading } = useLoading();
  const [opportunities, setOpportunities] = useState<PurchaseOpportunityDTO[]>([]);

  const goToForm = (id: string | number) => {
    navigate(urlReplace(RouteConstants.PURCHASE_OPPORTUNITY.REQUEST, { id }));
  };

  const fetchOpps = async () => {
    try {
      setLoading(true);
      const res: PurchaseOpportunityDTO[] = await getActivePurchaseOpportunities();
      setOpportunities(res);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void fetchOpps();
  }, []);

  return (
    <Card>
      <Card.Header>Purchase Opportunities</Card.Header>
      <Card.Body>
        <Title>Opportunities</Title>
        <Container>
          {opportunities?.length > 0 ? (
            <>
              {opportunities.map((opp: PurchaseOpportunityDTO) => (
                <PurchaseOpportunityCard
                  key={opp.purchaseOpportunityId}
                  opp={opp}
                  onClick={goToForm}
                  className={clsx({ "me-2": !isMobile, "mt-2": isMobile })}
                />
              ))}
            </>
          ) : !loading ? (
            <Typography elementStyles={{ style: "italic" }}>There are no current purchase opportunities</Typography>
          ) : null}
        </Container>
        <Title>My Entries</Title>
        <MyPurchaseOppTable />
      </Card.Body>
    </Card>
  );
};

export default PurchaseOpportunityList;
