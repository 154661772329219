import { Email, Column, Hint, Icon, Input, PhoneNumber, Row, Select, Textarea, ValueOpt } from "best-common-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { changeRequestGame, getMaxPriceOptions } from "../../../api/RequesTixApi";
import disclosureText from "../../../constants/DisclosureConstants";
import { useAuth } from "../../../contexts/AuthContext";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { useTicketRequest } from "../../../contexts/TicketRequestContext";
import { UserAllotmentCountDTO } from "../../../types/Allotment";
import { Business } from "../../../types/Business";
import { MaxPriceOption } from "../../../types/MaxPriceOption";
import { RequestType } from "../../../types/RequestType";
import { FlattenedTicketRequest } from "../../../types/TicketRequest";
import { isBusinessType, isChargeDepartment, isChargeIfNecessary, isCompOnly } from "../../../util/TicketRequestUtil";
import UserSearch from "../../user/search/UserSearch";
import BusinessSelect from "./BusinessSelect";
import CharityUseModal from "./CharityUseModal";
import GovernmentUseModal from "./GovernmentUseModal";
import TicketRequestBilling from "./TicketRequestBilling";
import TicketRequester from "./TicketRequester";
import TicketRequestNotificationsSelect from "./TicketRequestNotificationsSelect";
import TicketRequestProjectSelect from "./TicketRequestProjectSelect";
import TicketRequestSurroundingGames from "./TicketRequestSurroundingGames";

type CompRemainingStyleProps = {
  remaining: number;
  theme?: any;
};

const CompRemaining = styled.span<CompRemainingStyleProps>`
  font-size: 0.6875rem;
  font-style: oblique;
  font-weight: 300;
  line-height: 21px;
  color: ${(props: CompRemainingStyleProps) =>
    props.remaining === 0 ? props.theme.palette.coreColorsOnSurfaceRed : props.theme.palette.onColorsOnSurfaceLow};
`;

const Bold = styled.b`
  font-weight: 700;
`;

const DisclosureText = styled.div`
  font-family: Helvetica, serif;
  font-size: 0.75rem;
  font-weight: 300;
  font-style: oblique;
  color: ${(props) => props.theme.palette.onColorsOnSurfaceLow};
`;

const getOptionByValueKey = (options, value, key) => {
  const valueId = value ? value[key] : null;
  const option = options.find((opt) => opt.value[key] === valueId);
  return option ? option : [];
};

type TicketRequestFormProps = {
  ticketRequest: FlattenedTicketRequest;
  setTicketRequest: (value: FlattenedTicketRequest) => void;
  allotment: UserAllotmentCountDTO;
  canSelectGame?: boolean;
  canEditCreditCard?: boolean;
  phoneNumberRequired?: boolean;
};

const TicketRequestForm: React.FC<TicketRequestFormProps> = ({
  ticketRequest,
  setTicketRequest,
  allotment,
  canSelectGame = false,
  canEditCreditCard = true,
  phoneNumberRequired = false,
}) => {
  const { isSpringTraining } = useTicketRequest();
  const { isCoordinator, userInfo } = useAuth();
  const { requestTypesOptions, getOptionByValue, yesNoOptions } = useDropdowns();
  const [governmentUseModelOpen, setGovernmentUseModelOpen] = useState<boolean>(false);
  const [charityUseModelOpen, setCharityUseModelOpen] = useState<boolean>(false);
  const [payByOptions, setPayByOptions] = useState<ValueOpt<RequestType>[]>([]);
  const [maxPriceOptions, setMaxPriceOptions] = useState<ValueOpt<number>[]>([]);
  const [hideProjects, setHideProjects] = useState<boolean>(false);
  const [quantityOptions, setQuantityOptions] = useState<ValueOpt<number>[]>([]);

  const onChange = (key, value) => {
    setTicketRequest({ ...ticketRequest, [key]: value });
  };

  const onGameChange = (gamePk) => {
    changeRequestGame(ticketRequest.requestId, gamePk).then((res) => {
      const { originalGamePk, gamePk } = res;
      setTicketRequest({ ...ticketRequest, originalGamePk, gamePk });
    });
  };

  const onRequesterChange = (value) => {
    setTicketRequest({ ...ticketRequest, requester: value, notifyRequester: false });
  };

  const onRequestTypeChange = (value) => {
    const newQuantity = ticketRequest.quantity > 4 ? 0 : ticketRequest.quantity;
    setTicketRequest({ ...ticketRequest, requestType: value, quantity: newQuantity, userBilling: null });
  };

  const getMaxPriceOptionsData = async () => {
    const result: MaxPriceOption[] = await getMaxPriceOptions();
    const options = result.map((option) => ({
      label: option.description,
      value: option.maxPriceOptionId,
    }));
    setMaxPriceOptions(options);
  };

  useEffect(() => {
    const options = [];
    const isBusiness = isBusinessType(ticketRequest);
    const limit: number = isCompOnly(ticketRequest) || !isBusiness ? 4 : 500;
    for (let i = 1; i <= limit; i++) {
      if (isBusiness || i % 2 === 0) {
        options.push({
          label: i,
          value: i,
        });
      }
    }
    setQuantityOptions(options);
  }, [ticketRequest?.requestType]);

  useEffect(() => {
    let opts: ValueOpt<RequestType>[];
    if (isBusinessType(ticketRequest)) {
      if (isSpringTraining) {
        opts = requestTypesOptions.filter((option: ValueOpt<RequestType>) => option.value.requestTypeId != 1);
      } else {
        opts = requestTypesOptions;
      }
    } else {
      opts = requestTypesOptions.filter((option: ValueOpt<RequestType>) => option.value.requestTypeId === 1);
    }
    setPayByOptions(opts);
  }, [ticketRequest.type, requestTypesOptions, isSpringTraining]);

  useEffect(() => {
    void getMaxPriceOptionsData();
  }, []);

  return (
    <div>
      <GovernmentUseModal onClose={() => setGovernmentUseModelOpen(false)} isOpen={governmentUseModelOpen} />
      <CharityUseModal onClose={() => setCharityUseModelOpen(false)} isOpen={charityUseModelOpen} />
      <Row>
        {/* Requester */}
        {isCoordinator && (
          <Column width={3}>
            <TicketRequester value={ticketRequest.requester} onChange={onRequesterChange} />
          </Column>
        )}

        {/* Project */}
        {isBusinessType(ticketRequest) && !hideProjects && (
          <Column width={3}>
            <TicketRequestProjectSelect
              submitter={ticketRequest.submitter}
              projectId={ticketRequest.projectId}
              setProjectId={(value) => onChange("projectId", value)}
              setHide={setHideProjects}
            />
          </Column>
        )}

        {/* Business */}
        {isBusinessType(ticketRequest) && (
          <Column width={3}>
            <BusinessSelect
              businessId={ticketRequest.businessId}
              setBusiness={(value: Business) => onChange("businessId", value.businessId)}
              required={true}
            />
          </Column>
        )}

        {isBusinessType(ticketRequest) && !isCoordinator && <Column width={3} />}

        {isBusinessType(ticketRequest) && hideProjects && <Column width={3} />}

        {/* Request Type */}
        <Column width={3}>
          <Select
            id="paid-by"
            label={
              <div className="d-flex">
                <div>Pay By</div>
                <div>
                  {!!allotment && isCompOnly(ticketRequest) && !allotment.unlimited ? (
                    <CompRemaining className="ms-3" remaining={allotment.remaining}>
                      <Bold>{(allotment.remaining || 0).toString()}</Bold> of{" "}
                      <Bold>{(allotment.allowed || 0).toString()}</Bold> remaining
                    </CompRemaining>
                  ) : null}
                </div>
              </div>
            }
            placeholder="select payment method"
            options={payByOptions}
            required
            gutterBottom
            value={getOptionByValueKey(payByOptions, ticketRequest.requestType, "requestTypeId")}
            onChange={({ value }: ValueOpt<RequestType>) => onRequestTypeChange(value)}
            clearable={true}
          />
        </Column>

        {/* Quantity */}
        <Column width={3}>
          <Select
            name="quantity"
            id="quantity"
            label="Quantity"
            required
            gutterBottom
            placeholder="select the number of tickets"
            options={quantityOptions}
            value={getOptionByValue(quantityOptions, ticketRequest.quantity)}
            onChange={(value) => onChange("quantity", value.value)}
          />
        </Column>

        {!isBusinessType(ticketRequest) && !isCoordinator && <Column width={3} />}

        {/* Approving Supervisor */}
        {isBusinessType(ticketRequest) && (
          <Column width={3}>
            <UserSearch
              label="Approving Supervisor"
              id="approving-supervisor"
              required
              placeholderText="select a supervisor"
              existingUserIds={
                ticketRequest.approvingSupervisor ? [ticketRequest.approvingSupervisor.employeeUserId] : null
              }
              value={!!ticketRequest.approvingSupervisor ? ticketRequest.approvingSupervisor : undefined}
              onChange={(value) => onChange("approvingSupervisor", value)}
              onClear={() => onChange("approvingSupervisor", { employeeUserId: null })}
              clearable={true}
              isInvalid={ticketRequest.approvingSupervisor && !ticketRequest.approvingSupervisor.employeeUserId}
              restrictUserIds={[userInfo.employeeUserId]}
            />
          </Column>
        )}

        {/* Max Price */}
        {isChargeIfNecessary(ticketRequest) || isChargeDepartment(ticketRequest) ? (
          <Column width={3}>
            <Select
              name="maxPrice"
              id="maxPrice"
              label="Max Price Per Ticket"
              placeholder="select the price you are willing to pay"
              options={maxPriceOptions}
              value={getOptionByValue(maxPriceOptions, ticketRequest.maxPriceOptionId)}
              onChange={(value: ValueOpt<number>) => onChange("maxPriceOptionId", value.value)}
              clearable={true}
              gutterBottom
            />
          </Column>
        ) : null}

        {/* Credit Card */}
        {isChargeIfNecessary(ticketRequest) && (
          <TicketRequestBilling ticketRequest={ticketRequest} onChange={onChange} canEdit={canEditCreditCard} />
        )}

        {isChargeDepartment(ticketRequest) && <Column width={3} />}
        {(isChargeIfNecessary(ticketRequest) || isChargeDepartment(ticketRequest)) && <Column width={3} />}

        <Column width={3}>
          <Input
            label="Recipient First"
            required
            gutterBottom
            id="request-recipient-first"
            placeholder="Recipient First Name"
            value={ticketRequest.recipientFirst}
            onChange={(value) => onChange("recipientFirst", value)}
          />
        </Column>

        <Column width={3}>
          <Input
            id="request-recipient-last"
            label="Recipient Last"
            required
            gutterBottom
            placeholder="Recipient Last Name"
            value={ticketRequest.recipientLast}
            onChange={(value) => onChange("recipientLast", value)}
          />
        </Column>

        {/* Government Use */}
        <Column width={3}>
          <Select
            id="government-use-dropdown"
            label={
              <div className="d-flex">
                <div>For Government Official</div>
                <Icon
                  iconName="fa-info-circle"
                  className="ms-2"
                  styles={{ size: "0.9rem" }}
                  onClick={() => setGovernmentUseModelOpen(true)}
                />
              </div>
            }
            placeholder="select yes or no"
            options={yesNoOptions}
            value={yesNoOptions.find(({ value }) => value === ticketRequest.forGovernmentUse)}
            onChange={({ value }: ValueOpt<boolean>) => onChange("forGovernmentUse", value)}
            clearable={false}
            required
            gutterBottom
          />
        </Column>

        {/* Charity Use */}
        <Column width={3}>
          <Select
            id="charity-use-dropdown"
            label={
              <div className="d-flex">
                <div>For Charity</div>
                <Icon
                  iconName="fa-info-circle"
                  className="ms-2"
                  styles={{ size: "0.9rem" }}
                  onClick={() => setCharityUseModelOpen(true)}
                />
              </div>
            }
            placeholder="select yes or no"
            options={yesNoOptions}
            value={yesNoOptions.find(({ value }) => value === ticketRequest.forCharityUse)}
            onChange={({ value }: ValueOpt<boolean>) => onChange("forCharityUse", value)}
            clearable={false}
            required
            gutterBottom
          />
        </Column>

        {/* Delivery Email */}
        <Column width={3}>
          <Email
            id="email"
            label="Delivery Email"
            required
            gutterBottom
            value={ticketRequest.deliveryEmail}
            onChange={(value) => onChange("deliveryEmail", value)}
          />
        </Column>
      </Row>

      <Row>
        <Column width={3}>
          <PhoneNumber
            id="phoneNumber"
            label="Mobile Phone"
            value={ticketRequest.phoneNumber}
            onChange={(value) => onChange("phoneNumber", value)}
            required={phoneNumberRequired}
            gutterBottom
          />
        </Column>
        <Column width={2}>
          <div className="d-flex h-100 align-items-center">
            <Hint>
              Whenever possible tickets will be delivered digitally. Please check to be sure you have entered your
              Ballpark Email and Mobile Phone.
            </Hint>
          </div>
        </Column>
      </Row>

      <Row>
        <Column width={1}>
          <TicketRequestSurroundingGames
            originalGamePk={ticketRequest.originalGamePk}
            gamePk={ticketRequest.gamePk}
            selected={ticketRequest.surroundingGames as number[]}
            setSelected={(value) => onChange("surroundingGames", value)}
            canSelectGame={canSelectGame}
            onGameChange={onGameChange}
          />
        </Column>
      </Row>

      <Row>
        {/* Comments */}
        <Column width={1}>
          <Textarea
            id="request-comments"
            label="Comments"
            placeholder="enter any additional comments regarding your request"
            value={ticketRequest.comments}
            onChange={(value) => {
              if (value.length < 200) {
                onChange("comments", value);
              }
            }}
            gutterBottom
          />
        </Column>
      </Row>

      {/* Notification User */}
      {ticketRequest.requester?.employeeUserId !== ticketRequest.submitter?.employeeUserId && (
        <Row>
          <Column width={3}>
            <TicketRequestNotificationsSelect
              submitter={ticketRequest.submitter}
              requester={ticketRequest.requester}
              notifyRequester={ticketRequest.notifyRequester}
              onChange={onChange}
            />
          </Column>
        </Row>
      )}

      {/* Disclosure Text */}
      {ticketRequest.requestType?.requestTypeId && (
        <Row>
          <Column width={1}>
            <DisclosureText>
              {disclosureText[ticketRequest.type][ticketRequest.requestType.requestTypeId]}
            </DisclosureText>
          </Column>
        </Row>
      )}
    </div>
  );
};

export default TicketRequestForm;
