import React from "react";
import styled from "styled-components";
import { GamePreviewDTO } from "../../types/Game";
import { useTimezone } from "../../util/TimeUtil";
import EventCanceled from "../requests/misc/EventCanceled";
import { Card, MatchUp, Typography } from "best-common-react";

const Game = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GameInformation = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
`;

type GamePreviewDisplayProps = {
  game: GamePreviewDTO;
};

const GamePreviewDisplay = ({ game }: GamePreviewDisplayProps) => {
  const tz = game.venue ? game.venue.timeZone : null;
  const { time, day, date } = useTimezone(game.gameDate, tz, game.status);

  return (
    <Card>
      <Card.Body>
        <Game>
          <GameInformation>
            <Typography variant="titleMedium" elementStyles={{ weight: "bold" }}>
              {date}
            </Typography>
            <Typography>
              {day} {time}
            </Typography>
          </GameInformation>
          <MatchUp home={game.teams.home.team} away={game.teams.away.team} description={game.seriesDescription} />
        </Game>
        <EventCanceled game={game} />
      </Card.Body>
    </Card>
  );
};

export default GamePreviewDisplay;
