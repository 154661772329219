import {
  Button,
  Checkbox,
  CurrencyInput,
  Column,
  Modal,
  RadioGroup,
  RadioLabelValue,
  RadioValue,
  Row,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { billingTransaction, getReceiptPDF, getUserBillings } from "../../../../api/RequesTixApi";
import types from "../../../../constants/FulfillmentTypeConstants";
import { BillingDTO } from "../../../../types/UserBilling";
import { downloadLink, FileTypes } from "../../../../util/FileUtil";
import CreditCardSelect from "../../../payment/CreditCardSelect";
import { ActionProps } from "./ActionHandler";

const CreditCardHolder = styled.div.attrs(() => ({
  className: "me-1",
}))`
  width: 100%;
`;

const Charge = "Charge";
const Refund = "Refund";

const ReconcileWithOptions: RadioLabelValue[] = [
  { value: 1, label: "MLB" },
  { value: 2, label: "Team" },
];

const TransactionModal = ({ isOpen, close, requestId, amount, billings, chargeCard, type }) => {
  const [paidByBoc, setPaidByBoc] = useState(false);
  const [loading, setLoading] = useState(false);

  const save = () => {
    setLoading(true);
    const txDTO = {
      requestId: requestId,
      userBillingId: chargeCard.userBillingId,
      amount: amount,
      charge: type === Charge,
      paidByBoc: paidByBoc,
    };
    billingTransaction(txDTO)
      .then((data) => {
        close(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Modal show={isOpen} size="lg">
      <Modal.Header close={close}>{type} credit card</Modal.Header>
      <Modal.Body>
        <Row>
          <div className="col">Please confirm that you wish to complete the charge below</div>
        </Row>
        <Row className="mt-2">
          <Column width={2}>
            <CurrencyInput
              id="total-ticket-cost"
              label="Total Ticket Cost"
              disabled={true}
              value={amount}
              onChange={() => {}}
            />
          </Column>
          <Column width={2}>
            <CreditCardSelect
              id="credit-card-select"
              userBillings={billings}
              selected={chargeCard}
              setSelected={() => {}}
              disabled={true}
            />
          </Column>
          {type === Charge && (
            <Column width={2} className="mt-2">
              <RadioGroup
                id="reconcile-with"
                required={true}
                name="reconcileWith"
                options={ReconcileWithOptions}
                onChange={(value: RadioValue) => setPaidByBoc(value === 1)}
                value={paidByBoc ? 1 : 2}
              />
            </Column>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={save} loading={loading} disabled={!amount || !billings}>
          {type}
        </Button>
        <Button variant="default" onClick={() => close()}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const OrderChargedAction: React.FC<ActionProps> = ({ request, state, onStateChange, save }) => {
  const [chargeCard, setChargeCard] = useState<BillingDTO>();
  const [billings, setBillings] = useState<BillingDTO[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>(Charge);
  const [printing, setPrinting] = useState<boolean>(false);

  useEffect(() => {
    getUserBillings(request.requester.employeeUserId).then(setBillings);
  }, [request.requester.employeeUserId]);

  const refund = () => {
    setModalOpen(true);
    setModalType(Refund);
  };

  const charge = () => {
    setModalOpen(true);
    setModalType(Charge);
  };

  const modalClose = (data) => {
    setModalOpen(false);
    if (data) {
      onStateChange({ ...state, creditCardCharged: true });
      void save();
    }
  };

  const downloadReceipt = async () => {
    try {
      setPrinting(true);
      const response = await getReceiptPDF(request.requestId);
      downloadLink(response, FileTypes.PDF);
      return Promise.resolve();
    } catch (e) {
      console.error(e);
      return Promise.reject();
    } finally {
      setPrinting(false);
    }
  };

  useEffect(() => {
    if (request.userBilling) {
      setChargeCard(request.userBilling);
      onStateChange({ ...state, userBillingId: request.userBilling.userBillingId });
    }
  }, [request.userBilling]);

  return (
    !!state.fulfillmentTypeId &&
    state.fulfillmentTypeId !== types.COMP && (
      <>
        <TransactionModal
          isOpen={modalOpen}
          type={modalType}
          billings={billings}
          chargeCard={chargeCard}
          close={modalClose}
          amount={state.ticketCost}
          requestId={request.requestId}
        />
        <Column width={4} className="mt-1">
          <CurrencyInput
            id="totalTicketCost"
            value={state.ticketCost}
            label="Total Ticket Cost"
            placeholder="000.00"
            disabled={state.creditCardCharged}
            onChange={(value) => onStateChange({ ...state, ticketCost: value })}
            gutterBottom
          />
        </Column>
        <Column width={4} className="mt-1">
          <CurrencyInput
            id="totalOrderCost"
            value={state.orderCost}
            label="Total Order Cost"
            placeholder="000.00"
            onChange={(value) => onStateChange({ ...state, orderCost: value })}
            gutterBottom
          />
        </Column>
        {state.creditCardCharged && (
          <Column width={4} className="mt-1">
            <Button variant="default" className="mt-4" onClick={downloadReceipt} loading={printing}>
              Print Receipt
            </Button>
          </Column>
        )}
        {state.fulfillmentTypeId === types.CHARGE_CARD && (
          <div className="col-12 mt-1">
            <div className="d-flex align-items-center">
              <CreditCardHolder>
                <CreditCardSelect
                  id="red-card-select"
                  userBillings={billings}
                  selected={chargeCard}
                  setSelected={setChargeCard}
                  chargedDate={state.creditCardChargeDate}
                  required={false}
                  disabled={true}
                  gutterBottom
                />
              </CreditCardHolder>
              {state.creditCardCharged ? (
                <Button variant="default" onClick={refund}>
                  Refund
                </Button>
              ) : (
                <Button variant="default" onClick={charge}>
                  Charge
                </Button>
              )}
            </div>
          </div>
        )}
        {state.fulfillmentTypeId === types.CHARGE_DEPT && (
          <Column width={2} className="mt-1">
            <Checkbox
              id="departmentCardCharged"
              label="Department Card Charged"
              checked={!!state.deptCardCharged}
              onChange={() => onStateChange({ ...state, deptCardCharged: !state.deptCardCharged })}
              gutterBottom
            />
          </Column>
        )}
      </>
    )
  );
};

export default OrderChargedAction;
