import { Column, Row, Select, Title, ValueOpt } from "best-common-react";
import React, { useEffect, useState } from "react";
import { getActionsForState } from "../../../../api/RequesTixApi";
import { useDropdowns } from "../../../../contexts/DropdownsContext";
import { Action, ActionStateDTO } from "../../../../types/Action";
import { GameDTO } from "../../../../types/Game";
import { RequestState } from "../../../../types/RequestType";
import { FlattenedTicketRequest } from "../../../../types/TicketRequest";
import ActionHandler from "./ActionHandler";
import AdminComments from "./AdminComments";

type ActionFormProps = {
  game: GameDTO;
  statusId: number;
  setStatusId: (value: number) => void;
  actionState: ActionStateDTO;
  setActionState: (value: ActionStateDTO) => void;
  request: FlattenedTicketRequest;
  save: (showSuccessAlert?: boolean, refresh?: boolean) => Promise<any>;
  refresh: () => Promise<any>;
};

const ActionForm: React.FC<ActionFormProps> = ({
  game,
  statusId,
  setStatusId,
  actionState,
  setActionState,
  request,
  save,
  refresh,
}) => {
  const { requestStateOptions } = useDropdowns();
  const [actions, setActions] = useState<Action[]>([]);

  const getActionsState = async () => {
    if (statusId) {
      const data: Action[] = await getActionsForState(statusId);
      setActions(data);
    } else {
      setActions([]);
    }
  };

  useEffect(() => {
    void getActionsState();
  }, [statusId]);

  return (
    <>
      <Title>Status & Actions</Title>
      <Row>
        <Column width={2}>
          <Select
            id="requestStatus"
            label="Status"
            placeholder="Request Status..."
            value={requestStateOptions.find(
              (option: ValueOpt<RequestState>) => option.value.requestStateId === statusId
            )}
            options={requestStateOptions}
            onChange={(value) => setStatusId(value.value.requestStateId)}
            gutterBottom
          />
        </Column>
        {actions.map((action) => (
          <ActionHandler
            game={game}
            key={action.actionId}
            action={action}
            state={actionState}
            onStateChange={setActionState}
            request={request}
            save={save}
            statusId={statusId}
            refresh={refresh}
          />
        ))}
      </Row>
      <Row className="mt-0 mt-md-1">
        <Column width={1}>
          <AdminComments state={actionState} onStateChange={setActionState} />
        </Column>
      </Row>
    </>
  );
};

export default ActionForm;
