import {
  Button,
  Card,
  Column,
  DataTable,
  DataTableColumn,
  DateTimeFormatter,
  FormatterType,
  hexToRgb,
  Icon,
  Link,
  PriceFormatter,
  Row,
  StatsGameFormatter,
  StickyFooterButtons,
  TextFormatter,
  useLoading,
  useTheme,
  YearSelect,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMySuiteRequests } from "../../../api/RequesTixApi";
import UserDepartmentFormatter from "../../../components/tables/UserDepartmentFormatter";
import RouteConstants, { urlReplace } from "../../../constants/RouteConstants";
import { SuiteRequestDTO, SuiteRequestStatus, SuiteRequestStatusID, SuiteStatuses } from "../../../types/SuiteRequest";

const EditExpansionFormatter = ({ row }: FormatterType<SuiteRequestDTO>) => {
  const { suiteRequestStatus, suiteRequestId } = row ?? {};
  switch (suiteRequestStatus?.suiteStatusId) {
    case SuiteStatuses.OPEN:
      return (
        <Link
          to={{
            pathname: urlReplace(RouteConstants.REQUESTS.SUITES_REQUEST_EDIT, { suiteRequestId: suiteRequestId }),
          }}
        >
          <Icon iconName="fa-pencil-alt" />
        </Link>
      );
    case SuiteStatuses.IN_REVIEW:
    case SuiteStatuses.DENIED:
    case SuiteStatuses.APPROVED:
    default:
      return null;
  }
};

const StatusFormatter = ({ value, ...props }: FormatterType<SuiteRequestDTO>) => {
  const status: SuiteRequestStatus = value as SuiteRequestStatus;
  return <TextFormatter {...props} value={status.status} />;
};

const SuiteRequestColumns: DataTableColumn<SuiteRequestDTO>[] = [
  {
    name: "",
    key: "suiteRequestId",
    width: 35,
    readonlyFormatter: EditExpansionFormatter,
  },
  {
    name: "Game",
    key: "preferredGame",
    minWidth: 340,
    readonlyFormatter: StatsGameFormatter,
    sortable: true,
  },
  {
    key: "suiteRequestStatus",
    name: "Status",
    width: 200,
    readonlyFormatter: StatusFormatter,
  },
  {
    name: "Department",
    key: "onBehalfOfUser",
    readonlyFormatter: UserDepartmentFormatter,
    width: 250,
    sortable: true,
  },
  {
    name: "Capacity",
    key: "capacity",
    width: 150,
  },
  {
    name: "Budget",
    key: "ticketBudget",
    width: 120,
    readonlyFormatter: PriceFormatter,
    sortable: true,
  },
  {
    name: "Business Name",
    key: "paymentBusinessName",
    width: 250,
    sortable: true,
  },
  {
    name: "On Site Contact",
    key: "onSiteContact",
    width: 200,
    sortable: true,
  },
  {
    name: "Submitted",
    key: "submitted",
    readonlyFormatter: DateTimeFormatter,
    minWidth: 180,
    sortable: true,
  },
];

const MySuiteRequests = () => {
  const { Theme } = useTheme();
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const [suiteRequests, setSuiteRequests] = useState<SuiteRequestDTO[]>([]);
  const [year, setYear] = useState<number>(new Date().getFullYear());

  const fetchData = async () => {
    try {
      setLoading(true);
      const data: SuiteRequestDTO[] = await getMySuiteRequests(year);
      setSuiteRequests(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const goToNewRequest = () => {
    navigate(RouteConstants.REQUESTS.SUITES_REQUEST);
  };

  useEffect(() => {
    void fetchData();
  }, [year]);

  const statusTextOverride = (_selected: number, totalCount: number) =>
    totalCount === 1 ? `1 request` : `${totalCount} requests`;

  const rowStylesGetter = (_index: number, value: SuiteRequestDTO): object => {
    const statusId: SuiteRequestStatusID | undefined = value?.suiteRequestStatus?.suiteStatusId;
    switch (statusId) {
      case SuiteStatuses.APPROVED:
        return {
          background: hexToRgb(Theme.palette.coreColorsColoredSurfaceGreen, 0.4),
        };
      case SuiteStatuses.DENIED:
        return {
          background: hexToRgb(Theme.palette.coreColorsColoredSurfaceRed, 0.4),
        };
      case SuiteStatuses.IN_REVIEW:
        return {
          background: hexToRgb(Theme.palette.coreColorsColoredSurfaceBlue, 0.4),
        };
      case SuiteStatuses.OPEN:
      default:
        return {};
    }
  };

  return (
    <Card>
      <Card.Header>My Suite Requests</Card.Header>
      <Card.Body>
        <Row>
          <Column width={4}>
            <YearSelect
              id="suite-years"
              label="Year"
              minYear={2024}
              value={year}
              onChange={(value: number) => {
                setYear(value);
              }}
              gutterBottom
            />
          </Column>
        </Row>
        <DataTable
          columns={SuiteRequestColumns}
          data={suiteRequests}
          statusTextOverride={statusTextOverride}
          rowStylesGetter={rowStylesGetter}
        />
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons>
          <Button variant="default" onClick={goToNewRequest}>
            New Suite Request
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default MySuiteRequests;
