import { Accordion, CustomMobileAccordionType, DataTable, DataTableColumn, Table, useTheme } from "best-common-react";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Location } from "react-router-dom";
import { AdminRequestSeriesDTO } from "../../../../types/AdminRequest";
import { RequestixLocationState } from "../../../../types/Routing";
import {
  CompleteInCompleteFormatter,
  EventChangeFormatter,
  GameDateFormatter,
  LinkFormatter,
} from "./BySeriesFormatters";
import { BySeriesData, TotalData } from "./BySeriesTypes";
import { massageBySeriesData } from "./BySeriesUtil";

const TableHolder = styled.div.attrs(() => ({
  className: "mt-2 mt-md-4",
}))`
  height: 600px;
`;

type AdminRequestSeriesProps = {
  values: AdminRequestSeriesDTO[];
  location: Location<RequestixLocationState>;
};

const Columns: DataTableColumn<BySeriesData>[] = [
  {
    name: "Game",
    key: "gamePk",
    width: 330,
    readonlyFormatter: GameDateFormatter,
    sortable: true,
  },
  {
    name: "Total",
    key: "total",
    width: 70,
    cellClass: "justify-content-center",
    readonlyFormatter: LinkFormatter,
  },
  {
    name: "Open",
    key: "personalOpen",
    width: 70,
    cellClass: "justify-content-center",
    readonlyFormatter: LinkFormatter,
  },
  {
    name: "In Review",
    key: "personalInReview",
    width: 95,
    cellClass: "justify-content-center",
    readonlyFormatter: CompleteInCompleteFormatter,
  },
  {
    name: (
      <div className="d-flex flex-column">
        <div>Personal</div>
        <div>Approved</div>
      </div>
    ),
    key: "personalApproved",
    width: 110,
    cellClass: "justify-content-center",
    readonlyFormatter: CompleteInCompleteFormatter,
  },
  {
    name: "Denied",
    key: "personalDenied",
    width: 95,
    cellClass: "justify-content-center",
    readonlyFormatter: CompleteInCompleteFormatter,
  },
  {
    name: "Total",
    key: "personalTotal",
    width: 85,
    cellClass: "justify-content-center",
    readonlyFormatter: LinkFormatter,
  },
  {
    name: "Open",
    key: "businessOpen",
    width: 70,
    cellClass: "justify-content-center",

    readonlyFormatter: LinkFormatter,
  },
  {
    name: "In Review",
    key: "businessInReview",
    width: 95,
    cellClass: "justify-content-center",
    readonlyFormatter: CompleteInCompleteFormatter,
  },
  {
    name: (
      <div className="d-flex flex-column">
        <div>Business</div>
        <div>Approved</div>
      </div>
    ),
    key: "businessApproved",
    width: 110,
    cellClass: "justify-content-center",
    readonlyFormatter: CompleteInCompleteFormatter,
  },
  {
    name: "Denied",
    key: "businessDenied",
    width: 95,
    cellClass: "justify-content-center",
    readonlyFormatter: CompleteInCompleteFormatter,
  },
  {
    name: "Total",
    key: "businessTotal",
    width: 85,
    cellClass: "justify-content-center",

    readonlyFormatter: LinkFormatter,
  },
  {
    name: "Event Change",
    key: "eventChange",
    width: 200,
    readonlyFormatter: EventChangeFormatter,
  },
];

const GameView = ({ game }: { game: TotalData }) => (
  <div className="mb-3">
    <GameDateFormatter row={game} value={game.gamePk} subrow={true} />
    <div className="mt-2">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header style={{ width: "30%" }} />
            <Table.Header style={{ textAlign: "center" }}>Personal</Table.Header>
            <Table.Header style={{ textAlign: "center" }}>Business</Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.TdInfo>Open</Table.TdInfo>
            <Table.Td>
              <LinkFormatter value={game.personalOpen} row={game} subrow={true} />
            </Table.Td>
            <Table.Td>
              <LinkFormatter value={game.businessOpen} row={game} subrow={true} />
            </Table.Td>
          </Table.Row>
          <Table.Row>
            <Table.TdInfo>In Review</Table.TdInfo>
            <Table.Td>
              <CompleteInCompleteFormatter value={game.personalInReview} row={game} subrow={true} />
            </Table.Td>
            <Table.Td>
              <CompleteInCompleteFormatter value={game.businessInReview} row={game} subrow={true} />
            </Table.Td>
          </Table.Row>
          <Table.Row>
            <Table.TdInfo>Approved</Table.TdInfo>
            <Table.Td>
              <CompleteInCompleteFormatter value={game.personalApproved} row={game} subrow={true} />
            </Table.Td>
            <Table.Td>
              <CompleteInCompleteFormatter value={game.businessApproved} row={game} subrow={true} />
            </Table.Td>
          </Table.Row>
          <Table.Row>
            <Table.TdInfo>Denied</Table.TdInfo>
            <Table.Td>
              <CompleteInCompleteFormatter value={game.personalDenied} row={game} subrow={true} />
            </Table.Td>
            <Table.Td>
              <CompleteInCompleteFormatter value={game.businessDenied} row={game} subrow={true} />
            </Table.Td>
          </Table.Row>
          <Table.Row>
            <Table.TdInfo>Total</Table.TdInfo>
            <Table.Td>
              <LinkFormatter value={game.personalTotal} row={game} subrow={true} />
            </Table.Td>
            <Table.Td>
              <LinkFormatter value={game.businessTotal} row={game} subrow={true} />
            </Table.Td>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  </div>
);

const SeriesAccordion = ({ data, ...rest }: CustomMobileAccordionType<BySeriesData>) => (
  <Accordion {...rest}>
    <Accordion.Header>
      <GameDateFormatter row={data} value={data.gamePk} subrow={false} />
    </Accordion.Header>
    <Accordion.Body>
      {data.subRows.map((game, index) => (
        <GameView game={game} key={index} />
      ))}
    </Accordion.Body>
  </Accordion>
);

const AdminRequestSeries = ({ values, location }: AdminRequestSeriesProps) => {
  const { Theme } = useTheme();
  const [data, setData] = useState<BySeriesData[]>([]);

  const rowStylesGetter = useCallback(
    (index: number) => {
      if (index % 2 === 0) {
        return { background: Theme.palette.surfaceColorsSurfaceContainerLowest };
      } else {
        return { background: Theme.palette.surfaceColorsSurfaceContainerLow };
      }
    },
    [Theme]
  );

  useEffect(() => {
    setData(massageBySeriesData(values, location));
  }, [values]);

  return (
    <TableHolder>
      <DataTable
        data={data}
        columns={Columns}
        tableHeights={{
          headerHeight: 50,
          parentHeight: true,
        }}
        rowStylesGetter={rowStylesGetter}
        rowExpansion={{
          expandAllCallback: () => {},
          expandCallback: () => {},
          expandable: true,
          expandedKey: "expanded",
        }}
        displayExpandAllButton={false}
        accordion={SeriesAccordion}
        statusTextOverride={(_selected, totalCount) => `${totalCount} Series`}
      />
    </TableHolder>
  );
};

export default AdminRequestSeries;
