import { Button, Modal, Typography } from "best-common-react";
import React from "react";

const CharityUseModal = ({ isOpen, onClose }) => (
  <Modal show={isOpen} size="lg">
    <Modal.Header close={onClose}>Charity Use</Modal.Header>
    <Modal.Body>
      <Typography>
        Employees who wish to use a personal request for a charity donation must have prior approval from the Community
        Affairs department, as the organization must have an IRS nonprofit tax exempt 501c3 status. Please email &nbsp;
        <a href="mailto:charityticketrequests@mlb.com">charityticketrequests@mlb.com</a> &nbsp; no less than 16 days in
        advance of the game, including in your email: 1) date and location of requested game, 2) number of tickets, 3)
        the charity orgainization’s name, city and state, and tax ID or EIN number. If approved to proceed, you may then
        submit via Requestix as a personal request for further approval, noting the approved charity name in the
        comments. Approval from the Community Affairs department does not guarantee ticket approval as personal requests
        are not guaranteed and will be confirmed or denied within 14 days prior to the game for High Demand Market Clubs
        (noted above); all other Clubs will be confirmed or denied within 5 days of the desired game.
      </Typography>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="default" onClick={onClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

export default CharityUseModal;
