import { DraggableItem, Icon, Input, RequiredIndicator, Select, Table, ValueOpt } from "best-common-react";
import React, { useCallback, useEffect, useState } from "react";
import { useDropdowns } from "../../contexts/DropdownsContext";
import { VenueDeliveryMethod, VenueDeliveryMethodOptions } from "../../types/Venue";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

type DeliveryMethodsDnDProps = {
  deliveryMethods: VenueDeliveryMethod[];
  onReorder: (oldIndex: number, newIndex: number) => void;
  onChange: (index: number, field: string, value: any) => void;
  onDelete: (deliveryMethod: any) => void;
};

const DeliveryMethodsDnD = ({ deliveryMethods, onChange, onDelete, onReorder }: DeliveryMethodsDnDProps) => {
  const [localOptions, setLocalOptions] = useState<VenueDeliveryMethod[]>(deliveryMethods);
  const { venueDeliveryMethodsOptions } = useDropdowns();

  const getDropdownOpt = (
    venueDeliveryMethodsOptions: ValueOpt<VenueDeliveryMethodOptions>[],
    value: VenueDeliveryMethodOptions
  ) => venueDeliveryMethodsOptions.find((opt) => !!value && opt.value.id === value.id);

  const onDrag = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragItem = localOptions[dragIndex];
      const hoverItem = localOptions[hoverIndex];
      setLocalOptions((values: VenueDeliveryMethod[]) => {
        const updatedValues = [...values];
        updatedValues[dragIndex] = hoverItem;
        updatedValues[hoverIndex] = dragItem;
        return updatedValues;
      });
    },
    [localOptions]
  );

  useEffect(() => {
    setLocalOptions(deliveryMethods);
  }, [deliveryMethods]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header style={{ width: 30 }} />
            <Table.Header style={{ width: 30 }} />
            <Table.Header>
              Delivery Method <RequiredIndicator />
            </Table.Header>
            <Table.Header>
              Description <RequiredIndicator />
            </Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {localOptions.map((deliveryMethod, index) => (
            <DraggableItem
              groupName="delivery-methods"
              key={index}
              canDrag={true}
              index={index}
              onDrag={onDrag}
              onDrop={onReorder}
            >
              {({ passDownProps }) => (
                <Table.Row key={`row-${index}`} {...passDownProps}>
                  <Table.Td className="text-center align-middle">
                    <Icon iconName="fa-grip-horizontal" />
                  </Table.Td>
                  <Table.Td className="text-center align-middle">
                    <Icon iconName="fa-trash-alt" onClick={() => onDelete(deliveryMethod)} />
                  </Table.Td>
                  <Table.Td>
                    <Select
                      id="venue-delivery-method"
                      options={venueDeliveryMethodsOptions}
                      value={getDropdownOpt(venueDeliveryMethodsOptions, deliveryMethod.deliveryMethod)}
                      onChange={(value: ValueOpt<VenueDeliveryMethodOptions>) =>
                        onChange(index, "deliveryMethod", value.value)
                      }
                    />
                  </Table.Td>
                  <Table.Td>
                    <Input
                      id="venue-delivery-desc"
                      value={deliveryMethod.description}
                      onChange={(value: string) => onChange(index, "description", value)}
                    />
                  </Table.Td>
                </Table.Row>
              )}
            </DraggableItem>
          ))}
        </Table.Body>
      </Table>
    </DndProvider>
  );
};

export default DeliveryMethodsDnD;
