import { Button, Card, StickyFooterButtons, useLoading } from "best-common-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getReport, runReport } from "../api/RequesTixApi";
import ReportHandler from "../components/reports/ReportHandler";
import RouteConstants from "../constants/RouteConstants";
import { useAuth } from "../contexts/AuthContext";
import { ReportDataDTO, Reports } from "../types/Reports";
import { downloadLink, FileTypes } from "../util/FileUtil";
import { updateRouteParams, useRouteParams } from "../util/RouteUtil";

type ReportUrlProps = {
  reportId: string;
};

const ReportsContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { reportId } = useParams<ReportUrlProps>();
  const { userInfo, isAdmin } = useAuth();
  const { setLoading } = useLoading();
  const [report, setReport] = useState<Reports>();
  const [canExport, setCanExport] = useState(true);
  const routeData = useRouteParams(location.search);
  const reportData = {
    search: "",
    seasonId: null,
    categoryId: null,
    fulfillmentTypeId: null,
    statusId: null,
    userId: null,
    departmentId: null,
    projectId: null,
    gameSearchId: null,
    startDate: null,
    endDate: null,
    homeTeamId: null,
    ...routeData,
  };

  const exportReport = async () => {
    try {
      setLoading(true);
      const response = await runReport(reportId, reportData);
      downloadLink(response, FileTypes.EXCEL);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const setReportData = (reportData: ReportDataDTO) => {
    updateRouteParams(navigate, location.pathname, {
      search: reportData.search,
      seasonId: reportData.seasonId,
      categoryId: reportData.categoryId,
      fulfillmentTypeId: reportData.fulfillmentTypeId,
      statusId: reportData.statusId,
      userId: isAdmin ? reportData.userId : userInfo.employeeUserId,
      departmentId: reportData.departmentId,
      projectId: reportData.projectId,
      startDate: reportData.startDate,
      endDate: reportData.endDate,
      homeTeamId: reportData.homeTeamId,
      gameSearchId: reportData.gameSearchId,
    });
  };

  useEffect(() => {
    if (reportId) {
      getReport(reportId)
        .then(setReport)
        .catch(() => {
          navigate(RouteConstants.BASE);
        });
    }
  }, [reportId]);

  useEffect(() => {
    if (!reportData.userId) {
      const userData = {
        value: {
          ...userInfo,
          department: {
            departmentName: userInfo.department,
          },
        },
      };
      setReportData({ ...reportData, user: userData, userId: userInfo.employeeUserId });
    }
  }, [userInfo]);

  useEffect(() => {
    if (!reportData.userId && !isAdmin) {
      setReportData({ ...reportData, userId: userInfo.employeeUserId });
    }
  }, [reportData.userId, userInfo]);

  return (
    <Card>
      <Card.Header>{report?.reportName ?? ""}</Card.Header>
      <Card.Body>
        {!!report && (
          <ReportHandler
            report={report}
            reportData={reportData}
            setReportData={setReportData}
            location={location}
            setCanExport={setCanExport}
          />
        )}
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons>
          <Button variant="default" onClick={exportReport} disabled={!canExport}>
            Export
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default ReportsContainer;
