import { Button, GameInfo, Modal } from "best-common-react";
import React from "react";
import { deleteDayOfTicket } from "../../../api/RequesTixApi";
import { AdminDayOfTicket } from "../../../types/TodaysTickets";

type DeleteTodayTicketModalProps = {
  selectedTicket?: AdminDayOfTicket;
  setSelectedTicket: (ticket: AdminDayOfTicket | undefined) => void;
  refresh: () => Promise<any>;
};

const DeleteTodayTicketModal: React.FC<DeleteTodayTicketModalProps> = ({
  selectedTicket,
  setSelectedTicket,
  refresh,
}) => {
  const close = () => {
    setSelectedTicket(undefined);
  };

  const deleteTicket = async () => {
    try {
      await deleteDayOfTicket(selectedTicket.dayOfAvailableTicketId);
      close();
      refresh();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal show={!!selectedTicket} size="lg">
      <Modal.Header close={close}>Are you sure you want to delete this ticket</Modal.Header>
      <Modal.Body>
        {!!selectedTicket && (
          <div>
            <div>Please confirm you wish to delete this ticket</div>
            <div className="d-flex">
              <GameInfo
                gameDate={selectedTicket.game.gameDate}
                venue={selectedTicket.game.venue}
                home={selectedTicket.game.teams.home.team}
                away={selectedTicket.game.teams.away.team}
                seriesDescription={selectedTicket.game.seriesDescription}
              />
              <div className="ms-2">
                <span>Row: {selectedTicket.row}</span>
              </div>
              <div className="ms-2">
                <span>Section: {selectedTicket.section}</span>
              </div>
              <div className="ms-2">
                <span>Seat: {selectedTicket.seat}</span>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" disabled={false} onClick={deleteTicket}>
          Delete
        </Button>
        <Button variant="default" onClick={close}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteTodayTicketModal;
