import { Button, DATE_FORMATTER_STR, formatDate, Column, Textarea, useLoading } from "best-common-react";
import React from "react";
import styled from "styled-components";
import { ActionProps } from "./ActionHandler";
import ConfirmationEmailModal from "./ConfirmationEmailModal";
import { sendDigitalTicketsSms } from "../../../../api/RequesTixApi";

const ToEmail = styled.div`
  margin-left: 1.5rem;
  color: #666666;
  font-size: 0.9rem;
  font-weight: 300;
  font-style: italic;
`;

const SentDate = styled.span`
  margin-left: 0.5rem;
  color: #666666;
  font-size: 0.9rem;
  font-weight: 300;
  font-style: italic;
`;

const ConfirmationEmailSentAction: React.FC<ActionProps> = ({
  game,
  state,
  onStateChange,
  request,
  statusId,
  save,
}) => {
  const { setLoading } = useLoading();
  const [showEditModal, setShowEditModal] = React.useState(false);

  const textValue = state.confirmationText ? state.confirmationText : "";
  const email = request.notifyRequester ? request.requester.email : request.submitter.email;
  const title = statusId === 3 ? "Request approved" : "Request denied";
  const buttonLabel = !state.confirmationEmailSent ? "Send Confirmation Email" : "Resend Confirmation Email";
  const sentText =
    state && state.confirmationEmailSent
      ? state.confirmationEmailSentCount > 1
        ? "resent " + formatDate(new Date(state.confirmationEmailSentDate), DATE_FORMATTER_STR)
        : "sent " + formatDate(new Date(state.confirmationEmailSentDate), DATE_FORMATTER_STR)
      : "";

  const onClose = () => {
    setShowEditModal(false);
  };

  const sendSms = async () => {
    try {
      setLoading(true);
      await sendDigitalTicketsSms(request.requestId);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ConfirmationEmailModal
        onClose={onClose}
        isOpen={showEditModal}
        request={request}
        state={state}
        title={title}
        statusId={statusId}
        save={save}
      />
      <Column width={1}>
        <Textarea
          id="confirmationEmailNotes"
          label="Confirmation Email Notes"
          placeholder="add any additional notes to be sent in the confirmation email…"
          disabled={false}
          onChange={(value: string) => {
            onStateChange({ ...state, confirmationText: value });
          }}
          value={textValue}
        />
        <div className="d-flex align-items-center my-2">
          <Button variant="secondary" id="confirmationEmail" onClick={() => setShowEditModal(true)} disabled={false}>
            {buttonLabel}
          </Button>
          <ToEmail>{email}</ToEmail>
          <SentDate>{sentText}</SentDate>
        </div>
        {game.allowSmsTickets && (
          <div>
            <Button variant="secondary" id="sendSms" onClick={sendSms} disabled={false}>
              Text Tickets
            </Button>
          </div>
        )}
      </Column>
    </>
  );
};

export default ConfirmationEmailSentAction;
